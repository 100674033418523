<template>
    <!--  -->
    <el-dialog :close-on-click-modal="false" :title="formType == 0?'编辑联单':'绑定企业'" :visible.sync="show" width="550px"
        class="selfInputBox selfInputBoxIV">




        <!--  -->



        <!--   <div class="buttons buttonsII">
            <el-button type="primary" :class="{'base':formType!=0}" @click="formType=0">联单信息</el-button>
            <el-button type="primary" :class="{'base':formType!=1}" @click="formType=1">基本信息</el-button>
        </div> -->


        <div v-if="formType==0">

            <div class="PopContentBox">

                <el-form v-if="formShow" ref="form" label-width="150px">

                    <!-- <el-form-item label="唯一标识" prop="">
                <el-input v-model="orderInfo.name" placeholder="请输入唯一标识" />
            </el-form-item> -->

                    <!-- <el-form-item label="医废类型">
                <el-select clearable v-model="orderInfo.item_id" placeholder="请选择医废类型" class="SelfSelect">
                    <el-option :label="item.name" :value="item.id" v-for="item in ItemTypeList"
                        :key="item.id"></el-option>
                </el-select>
            </el-form-item> -->

                    <el-form-item label="废物名称">
                        <el-select clearable v-model="SelectionID" placeholder="请选择废物名称" class="SelfSelect">
                            <el-option :label="item.item_name" :value="item.item_id" v-for="item in ItemTypeList"
                                :key="item.item_id"></el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="废物代码" prop="">
                        <el-input disabled v-model="orderInfo.item_code" />
                    </el-form-item>


                    <!-- <el-form-item label="所属医院">
                <el-select :disabled="!orderInfo.is_first" v-model="orderInfo.dep_id" placeholder="请选择所属医院"
                    class="SelfSelect">
                    <el-option :label="item.name" :value="item.id" v-for="item in depList" :key="item.id"></el-option>
                </el-select>
            </el-form-item> -->

                    <!-- <el-form-item label="所属医院" prop="">
                        <el-input disabled v-model="hospital.dep_name" />
                    </el-form-item> -->

                    <!-- 包装类型：1包装袋、2利器盒 -->
                    <el-form-item label="包装类型">
                        <!-- :disabled="!orderInfo.is_first" -->
                        <el-select disabled v-model="orderInfo.packing_id" placeholder="请选择包装类型" class="SelfSelect">
                            <el-option :label="'包装袋'" :value="'6'"></el-option>
                            <el-option :label="'利器盒'" :value="'5'"></el-option>
                        </el-select>

                        <!-- "packing_id": 1, //包装类型：、5利器盒 6包装袋 -->
                        <!-- <span v-if="scope.row.packing_id==5">
                            利器盒
                        </span>
                        <span v-if="scope.row.packing_id==6">
                            包装袋
                        </span> -->

                    </el-form-item>


                    <el-form-item label="包装数量" prop="">
                        <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.quantity"
                            placeholder="请输入包装数量" />
                    </el-form-item>

                    <!-- <el-form-item label="总重量" prop="">
                        <el-input :disabled="!orderInfo.is_first" class="weight" v-model="orderInfo.weight"
                            placeholder="请输入总重量" />
                    </el-form-item> -->

                    <!-- <el-form-item label="本院科室数量1" prop="">
                        <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.level_quantity"
                            placeholder="请输入本院科室数量" />
                    </el-form-item>

                    <el-form-item label="本院科室重量1" prop="">
                        <el-input :disabled="!orderInfo.is_first" class="weight" v-model="orderInfo.level_weight"
                            placeholder="请输入本院科室重量" />
                    </el-form-item>

                    <el-form-item label="本院辖区卫生室数量1" prop="">
                        <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.dep_quantity"
                            placeholder="请输入本院辖区卫生室数量" />
                    </el-form-item>

                    <el-form-item label="本院辖区卫生室重量1" prop="">
                        <el-input :disabled="!orderInfo.is_first" class="weight" v-model="orderInfo.dep_weight"
                            placeholder="请输入本院辖区卫生室重量" />
                    </el-form-item> -->


                    <!-- <el-form-item label="第三方数量" prop="">
                        <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.tripartite_quantity"
                            placeholder="请输入第三方数量" />
                    </el-form-item>
                    -->
                    <el-form-item label="第三方单位接收量(公斤)" prop="">
                        <el-input :disabled="!orderInfo.is_first" class="weight" v-model="orderInfo.tripartite_weight"
                            placeholder="请输入第三方单位接收量(公斤)" />
                    </el-form-item>


                    <!-- v-model="orderInfo.tripartite_quantity" -->

                    <el-form-item label="接收单位数" prop="">
                        <!-- <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.dep_number"
                            placeholder="请输入接收单位数" />
 -->

                        <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.dep_number"
                            placeholder="请输入接收单位数" />


                    </el-form-item>






                    <!-- <el-form-item label="本单位产生数量" prop="">
                        <el-input :disabled="!orderInfo.is_first" class="num" v-model="orderInfo.this_quantity"
                            placeholder="请输入本单位产生数量" />
                    </el-form-item> -->

                    <el-form-item label="本单位产生量(公斤)" prop="">
                        <el-input :disabled="!orderInfo.is_first" class="weight" v-model="orderInfo.this_weight"
                            placeholder="请输入本单位产生量(公斤)" />
                    </el-form-item>



                </el-form>


                <div class="orderInfoChild" v-show="orderInfo.child != undefined && orderInfo.child.length>0">

                    <span class="title">
                        三方单位接收 {{orderInfo.tripartite_quantity}}包，{{orderInfo.tripartite_weight}}Kg
                    </span>

                    <el-collapse class="collapse">
                        <el-collapse-item title="" v-for="item in orderInfo.child" :key="item.dep_id">

                            <template slot="title">
                                {{item.dep_name}}<i class="layui-icon layui-icon-ok status-icon-ok"></i>
                            </template>

                            <div>
                                <span>包数：{{item.quantity}}包</span>
                                <span>重量：{{item.weight}}KG</span>
                            </div>
                        </el-collapse-item>
                    </el-collapse>

                </div>

            </div>

        </div>



        <div v-if="formType==1">

            <div class="PopContentBox">

                <el-form v-if="formShow" ref="form" label-width="100px">

                    <el-form-item label="企业名称">
                        <el-input style="margin-left: -5px;" v-model="qymc" />
                    </el-form-item>

                </el-form>

            </div>

        </div>






        <span slot="footer">
            <div class="buttons">

                <el-button v-if="formType==1" type="primary" @click="save">确认</el-button>
                <el-button v-else type="primary" @click="save">上传</el-button>

                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>

    </el-dialog>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'


    export default {
        // model: {
        //     prop: 'value',
        //     event: 'change'
        // },
        // props: {
        //     value: {
        //         type: Object,
        //         required: true
        //     }
        // },

        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                formType: 0,
                qymc: "",


                orderInfo: {},
                SelectionID: "",
                hospital: {},

                ItemTypeList: [],
                depList: [],

                // isEdit: false

            }
        },

        computed: {
            ...mapState('m_item', ['LevelList', 'PositionList', 'DepList', 'HealthList', 'Province', 'City', 'District',
                'RoleList', 'Depcate', 'ItemType'
            ]),
        },

        watch: {
            show(value) {
                if (this.show) {

                    // this.getitemType()
                    // setTimeout(() => {
                    //     this.ItemTypeList = this.ItemType
                    // }, 1000)

                    this.getHazardousStay()

                    if (this.depList.length == 0) {
                        this.getdep()
                        setTimeout(() => {
                            this.depList = this.DepList
                        }, 1000)
                    }

                    this.hospital = {
                        dep_name: JSON.parse(localStorage.getItem("user")).dep_name,
                        dept_id: JSON.parse(localStorage.getItem("user")).dept_id
                    }



                    var a = JSON.parse(localStorage.getItem('user'))

                    if (a.qybh == null || a.qybh == undefined || a.qymc == null || a.qymc == undefined) {

                        // 企业编号
                        // qybh: null
                        // 企业名称
                        // qymc: null
                        this.formType = 1

                        // setTimeout(() => {
                        //     this.$message.error("请先绑定医院");
                        // }, 500)

                        return
                    }




                } else {
                    // this.isEdit = false

                    setTimeout(() => {
                        this.formType = 0
                    }, 300)

                    this.orderInfo = {}
                    this.SelectionID = ""
                    this.hospital = {}
                }

            },

            SelectionID(value) {
                this.ItemTypeList.map(x => {
                    if (x.item_id == value) {
                        this.orderInfo = x

                        // 损伤性 利器盒
                        // 其他 包装袋

                        // <!-- 包装类型：1包装袋、2利器盒 -->
                        x.item_id == 3 ?
                            (this.orderInfo.packing_id = '5') :
                            (this.orderInfo.packing_id = '6')


                        // <!-- "packing_id": 1, //包装类型：、5利器盒 6包装袋 -->
                        // <span v-if="scope.row.packing_id==5">
                        //     利器盒
                        // </span>
                        // <span v-if="scope.row.packing_id==6">
                        //     包装袋
                        // </span>



                        this.orderInfo.dep_number = this.orderInfo.child.length



                    }
                })


            }

        },

        methods: {



            getHazardousStay() {

                this.$http.get('/api/hazardous/stay').then((res) => {
                    if (res.code == 200) {

                        this.ItemTypeList = res.data

                    }
                }).catch((err) => {
                    console.log(err)
                });

            },

            open() {
                // this.orderInfo = {
                //     name: "",
                //     sort: ""
                // }

                this.formShow = true
                this.show = true
            },


            // edit(val) {

            //     this.open()

            //     this.isEdit = true

            //     // this.orderInfo = {
            //     //     ...val
            //     // }

            //     // this.$http.get('/api/admin/' + val.id).then((res) => {
            //     //     if (res.code == 200) {
            //     //         this.setInfo(res.data)
            //     //     }
            //     // }).catch((err) => {
            //     //     console.log(err)
            //     // });

            // },

            save: _.debounce(
                function() {

                    console.log(this.formType);





                    var a = JSON.parse(localStorage.getItem('user'))


                    if (this.formType == 0) {
                        if (a.qybh == null || a.qybh == undefined || a.qymc == null || a.qymc == undefined) {

                            // 企业编号
                            // qybh: null
                            // 企业名称
                            // qymc: null

                            this.formType = 1

                            this.$message.error("请先绑定医院");

                            return
                        }
                    }

                    if (this.formType == 1) {

                        if (this.qymc.length == 0 || this.qymc == null) {

                            this.$message.error("企业名称不能为空");
                            return
                        }


                        const loading = this.$loading({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        // setTimeout(() => {
                        //   loading.close();
                        // }, 2000);



                        this.$http.get('/api/hazardous/getEnterpriseByName', {
                            name: this.qymc

                        }).then((res) => {

                            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                            loading.close();

                            if (res.code != 200) { //请求错误
                                this.$message.error(res.msg);
                            }

                            if (res.code == 200) {

                                var a = JSON.parse(localStorage.getItem('user'))
                                a.qybh = res.data.qybh
                                a.qymc = res.data.qymc

                                localStorage.setItem('user', JSON.stringify(a))


                                this.$message.success(res.msg);

                                setTimeout(() => {
                                    this.formType = 0

                                }, 1000)

                            }

                        }).catch((err) => {
                            console.log(err)
                        });


                        return
                    }









                    this.orderInfo.dep_id = this.hospital.dept_id



                    this.orderInfo.qybh = JSON.parse(localStorage.getItem('user')).qybh

                    console.log(this.orderInfo);


                    // dep_id: 126
                    // packing_id: "1"




                    // if (this.isEdit) {

                    //     this.$http.put('/api/depcate/' + this.orderInfo.id, this.info).then((res) => {

                    //         console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    //         if (res.code != 200) { //请求错误
                    //             this.$message.error(res.msg);

                    //         }

                    //         if (res.code == 200) {
                    //             this.close()
                    //         }

                    //     }).catch((err) => {
                    //         console.log(err)
                    //     });

                    // } else {

                    // var array = [this.orderInfo].map(x => {
                    //     x.dep_number = x.child.length

                    //     delete x.child

                    //     x.dep_id = Number(JSON.parse(localStorage.getItem("user")).dept_id)
                    //     x.packing_id = x.item_id == 2 ? 2 : 1
                    //     // form.item_id==2?'利器盒':'包装袋'
                    //     // <!-- 包装类型：1包装袋、2利器盒 -->
                    //     return x
                    // })



                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    // setTimeout(() => {
                    //   loading.close();
                    // }, 2000);





                    // this.$http.post('/api/hazardous', this.orderInfo).then((res) => {
                    // this.$http.post('/api/Hazardous/noCheckSave', this.orderInfo).then((res) => {
                    this.$http.post('/api/Hazardous/noCheckSave', [this.orderInfo]).then((res) => {

                        // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        loading.close();

                        if (res.code == 200) {
                            this.close()
                        }

                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);
                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                    // }

                },
                1000),

            close() {
                this.show = false
                // this.$emit('refresh')

                this.$parent.getList();

                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

        }
    }
</script>

<style scoped>
    .SelfSelect {
        /* border: 1px red solid;
       box-sizing: border-box; */
    }

    /deep/ .el-input__inner {
        /* border: 1px green solid;
        box-sizing: border-box; */

        width: calc(100% - 80px) !important;
    }
</style>


<style scoped lang="scss">
    /deep/.el-form-item__label {
        width: 170px !important;
        /* position: relative;
        left: 20px; */

        // border: 1px green solid;
        // box-sizing: border-box;
    }

    /* .unit {
        position: relative;
    } */


    .buttonsII {
        // border: 1px green solid;
        // box-sizing: border-box;
        position: relative;
        top: -25px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .base {
            color: #768dfc;
            background: #fff;
            border-color: #768dfc;
        }

    }

    .PopContentBox {
        // border: 1px green solid;
        // box-sizing: border-box;
        max-height: 500px;
        overflow: auto;
    }





    .num::before {
        content: "包";
        position: absolute;
        right: 36px;
        z-index: 9;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        color: #6A83FC;
        /* border: 1px green solid;
        box-sizing: border-box; */
    }

    .weight::before {
        content: "KG";
        position: absolute;
        right: 36px;
        z-index: 9;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        color: #6A83FC;
        /* border: 1px green solid;
        box-sizing: border-box; */
    }



    .orderInfoChild {
        padding: 10px 50px;
        border-top: 2px #ccc dotted;
        box-sizing: border-box;


        .title {
            padding: 10px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #6A83FC;
            line-height: 20px;

            // border: 1px green solid;
            // box-sizing: border-box;

        }

        .collapse {
            border: 1px solid #dfdfdf;
            box-sizing: border-box;
            margin-top: 10px;

            border-radius: 6px;
            overflow: hidden;

            i {
                margin-left: 10px;
            }


            /deep/ .el-collapse-item__header {
                padding: 0 30px;
                position: relative;
            }

            /deep/ .el-collapse-item__arrow {
                position: absolute;
                left: 10px;
            }

            /deep/.el-icon-arrow-right:before {
                content: "\e791";
                color: #6A83FC;
            }




            /deep/ .el-collapse-item__header.is-active {
                border-bottom: 2px dotted #dfdfdf;
                box-sizing: border-box;

            }

            /deep/ .el-collapse-item__wrap {
                padding: 10px;
            }

            /deep/ .el-collapse-item__content {
                padding-bottom: 0px;

                div {
                    // border: 1px green solid;
                    // box-sizing: border-box;

                    // width: 70%;
                    // display: flex;
                    span {
                        margin-right: 20px;
                    }

                }
            }


        }

    }
</style>