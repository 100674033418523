<template>

    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true">



                <el-form-item label="医废类型">
                    <el-select clearable v-model="wasteInfo.item_id" placeholder="请选择医废类型" class="SelfSelect">
                        <el-option :label="item.name" :value="item.id" v-for="item in ItemTypeList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="所属医院">
                    <el-cascader v-model="wasteInfo.dep_id" placeholder="请选择所属医院" :options="depList" filterable
                        clearable :props="{
                            label:'name', children:'children',checkStrictly:'true',value:'id' }"></el-cascader>
                </el-form-item>


                <el-form-item prop="title">

                    <el-date-picker v-model="wasteInfo.create_time" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd" style="height: 36px; display: flex;align-items: baseline;">
                    </el-date-picker>

                </el-form-item>



                <el-form-item>

                    <el-button type="primary" @click="searchName">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <el-button type="primary" @click="toSearch" v-if="getPurviewNew('sys:hazardous:add')">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        生成联单
                    </el-button>

                </el-form-item>






                <el-form-item v-if="getPurviewNew('sys:hazardous:export')">
                    <json-excel :data="json_data" :fields="json_fields" worksheet="全部" name="危废联单.xls"
                        :header="[header]" :footer="[footer]">
                        <el-button type="primary">导出危废联单</el-button>
                    </json-excel>
                </el-form-item>




            </el-form>
        </div>




        <!-- <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border ref="FanfuTable"> -->

        <el-table class="el-tableII FanfuTableBox " :data="data.list" stripe height="100%" border ref="multipleTable"
            v-loading="loading">

            <!-- <el-table-column show-overflow-tooltip type="selection" min-width="40" align="center" fixed="left" /> -->
            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />


            <!-- <el-table-column show-overflow-tooltip prop="number" label="唯一标识" align="left" min-width="180" /> -->





            <!-- <el-table-column show-overflow-tooltip prop="dep_name" label="企业名称" align="left" min-width="150" /> -->

            <!-- <el-table-column show-overflow-tooltip prop="item_name" label="类型" align="left" min-width="60" /> -->
            <el-table-column show-overflow-tooltip prop="item_name" label="废物名称" align="left" min-width="100" />

            <el-table-column show-overflow-tooltip prop="item_code" label="废物代码" align="left" min-width="100" />

            <el-table-column show-overflow-tooltip prop="" label="包装类型" align="left" min-width="100">
                <template slot-scope="scope">
                    <!-- "packing_id": 1, //包装类型：1包装袋、2利器盒 -->
                    <!-- <span v-if="scope.row.packing_id==1">
                        包装袋
                    </span>
                    <span v-else>
                        利器盒
                    </span> -->


                    <!-- "packing_id": 1, //包装类型：、5利器盒 6包装袋 -->
                    <span v-if="scope.row.packing_id==5">
                        利器盒
                    </span>
                    <span v-if="scope.row.packing_id==6">
                        包装袋
                    </span>

                </template>
            </el-table-column>


            <!-- <el-table-column show-overflow-tooltip prop="quantity" label="总数量" align="left" /> -->
            <el-table-column show-overflow-tooltip prop="quantity" label="包装数量" align="left" min-width="100" />

            <el-table-column show-overflow-tooltip prop="tripartite_weight" label="第三方单位接收量(公斤)" align="left"
                min-width="150" />


            <el-table-column show-overflow-tooltip prop="dep_number" label="接收单位数" align="left" min-width="110" />


            <el-table-column show-overflow-tooltip prop="this_weight" label="本单位产生量(公斤)" align="left" min-width="130" />







            <!-- <el-table-column show-overflow-tooltip prop="this_quantity" label="本单位产生数量" align="left" min-width="110" />
            <el-table-column show-overflow-tooltip prop="tripartite_quantity" label="第三方产生数量" align="left"
                min-width="110" /> -->
            <!-- <el-table-column show-overflow-tooltip    prop="level_quantity" label="科室产生数量" align="left" />
            <el-table-column show-overflow-tooltip    prop="dep_quantity" label="机构产生数量" align="left" /> -->


            <!-- <el-table-column show-overflow-tooltip prop="weight" label="总重量(KG)" align="left" min-width="110" />
            <el-table-column show-overflow-tooltip prop="this_weight" label="本单位产生重量(KG)" align="left"
                min-width="140" /> -->
            <!-- <el-table-column show-overflow-tooltip prop="tripartite_weight" label="第三方产生重量(KG)" align="left"
                min-width="140" /> -->
            <!-- <el-table-column show-overflow-tooltip    prop="level_weight" label="科室产生重量(KG)" align="left" />
            <el-table-column show-overflow-tooltip    prop="dep_weight" label="机构产生重量(KG)" align="left" /> -->



            <!-- <el-table-column show-overflow-tooltip prop="" label="状态" align="left" width="90">
                <template slot-scope="scope">
                    <span v-if="scope.row.state==1">
                        <i class="layui-icon layui-icon-ok status-icon-ok"></i>
                        正常
                    </span>
                    <span v-else>
                        <i class="layui-icon layui-icon-close status-icon-close"></i>
                        异常
                    </span>
                </template>
            </el-table-column> -->


            <el-table-column show-overflow-tooltip prop="create_time" label="上传时间" align="left" min-width="150" />


            <!-- <el-table-column show-overflow-tooltip    prop="" label="状态" align="left"  width="90">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.status" :active-value="1" active-color="#5BD995" active-text="正常"
                        :inactive-value="2" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                    </el-switch>
                </template>
            </el-table-column> -->


            <el-table-column show-overflow-tooltip label="操作" min-width="80" align="center">
                <template slot-scope="scope">
                    <div class="operate">
                        <el-button @click="view(scope.row)">
                            查看
                        </el-button>
                        <!-- <el-button type="primary" @click="edit(scope.row)">
                            编辑
                        </el-button>
                        <el-button type="danger" @click="del(scope.row)">
                            删除
                        </el-button> -->
                    </div>
                </template>
            </el-table-column>

        </el-table>
        <!-- </Fanfu-Table> -->

        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" />


        <!-- v-model="search" @refresh="getList(data.size)" -->
        <Search ref="searchRef" />


        <HazardousInfo ref="HazardousInfo" />

    </div>
</template>

<script>
    import Search from './components/HazardousSearch'

    import HazardousInfo from './components/HazardousInfo'


    import JsonExcel from 'vue-json-excel'

    import {
        mapState,
        mapMutations
    } from 'vuex'


    import resizeDetector from 'element-resize-detector'

    export default {
        components: {
            Search,
            HazardousInfo,
            JsonExcel
        },
        data() {
            return {

                loading: false,
                select: [],
                data: {
                    list: [],
                    page: 1,
                    size: 15,
                    total: 0
                },

                wasteInfo: {
                    item_id: '', //医废类型标识id
                    create_time: '',
                    dep_id: '', //组织机构标识id
                },

                ItemTypeList: [], //医废类型

                depList: [], //机构

                header: "",
                footer: "",
                json_fields: {},
                json_data: [],


                loading: false,


            }
        },

        computed: {
            ...mapState('m_item', [
                'LevelList',
                'PositionList',
                'DepList',
                'DepTree',
                'HealthList',
                'Province',
                'City',
                'District',
                'RoleList',
                'Depcate',
                'ItemType'
            ]),

        },

        mounted() {
            this.modifyLayout();
        },
        created() {
            this.modifyLayout();

            this.getList();

            // 数据处理
            this.DataProcessing()


            this.getitemType()
            setTimeout(() => {
                this.ItemTypeList = this.ItemType
            }, 1000)

            this.getdeptree()
            setTimeout(() => {
                this.depList = this.DepTree
                // this.depListHandle(this.DepTree)
            }, 1000)





            const routeParams = window.location.hash;
            var a = routeParams.split("id=")
            if (a.length > 1) {

                console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%", a);

                this.loading = true

                var x = a[1]

                this.getPidArray(x, [])

            }

        },

        watch: {
            // created_time(val) {
            //     console.log("~~~~~~~~~~~~~~~~~~~~~~", val);
            //     this.data.page = 1
            //     this.getList()
            //     // 数据处理
            //     this.DataProcessing()
            // }

            "wasteInfo": {
                deep: true, //深度监听设置为 true
                handler: function(newVal, oldVal) {
                    // this.tosearch()

                    this.getList()
                    // 数据处理
                    this.DataProcessing()

                }
            },


        },
        methods: {

            getPidArray(id, array) {

                var a = [...array]

                a.unshift(Number(id))


                this.$http.get('/api/dep/' + id).then((res) => {


                    if (res.code == 200) {


                        if (res.data.pid == 0) {

                            // this.$nextTick(() => {

                            this.wasteInfo.dep_id = a
                            // console.log("￥￥￥￥￥￥￥￥", a);

                            // })

                            setTimeout(() => {
                                this.loading = false
                            }, 1000)


                        } else {
                            this.getPidArray(res.data.pid, a)
                        }

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });



            },




            getList() {

                var a = {
                    ...this.wasteInfo
                }

                if (this.wasteInfo.create_time && this.wasteInfo.create_time.length > 0) {
                    a.create_time = this.wasteInfo.create_time.toString()
                }


                if (this.wasteInfo.dep_id && this.wasteInfo.dep_id.length > 0) {
                    a.dep_id = this.wasteInfo.dep_id.slice(-1).toString()
                }



                a.page_size = this.data.size
                a.page = this.data.page


                this.$http.get('/api/hazardous', a).then((res) => {

                    if (res.code == 200) {

                        this.data.list = res.data.data
                        // .map(x => {
                        //     x.number = "1514225662342321374441"
                        //     return x
                        // })

                        this.data.total = res.data.total

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });



            },


            // 数据处理
            DataProcessing() {

                var a = {
                    ...this.wasteInfo
                }

                if (this.wasteInfo.create_time && this.wasteInfo.create_time.length > 0) {
                    a.create_time = this.wasteInfo.create_time.toString()
                }


                if (this.wasteInfo.dep_id && this.wasteInfo.dep_id.length > 0) {
                    a.dep_id = this.wasteInfo.dep_id.slice(-1).toString()
                }



                a.page_size = 9999
                a.page = 1




                this.$http.get('/api/hazardous', a).then((res) => {

                    if (res.code == 200) {

                        var val = res.data.data

                        this.header = "危废联单"

                        this.json_fields = {
                            "联单编号": {
                                field: 'hazardous_no',
                                callback: (val) => {
                                    if (val) {
                                        return `'${val}`
                                    } else {
                                        return ``
                                    }
                                }
                            },
                            "联单状态": {
                                field: 'state',
                                callback: (val) => {
                                    if (val == 1) {
                                        return "正常"
                                    } else {
                                        return "异常"
                                    }
                                }
                            },
                            "废物俗称": "item_name",
                            "废物代码": "item_code",
                            "填领时间": "create_time",
                            // "转移时间"	
                            "移出量(kg)": "weight",
                            // "办结时间"	
                            "接收量(kg)": "weight",
                            "产废单位": "dep_name",
                            "经营单位": {
                                field: '',
                                callback: () => {
                                    return "天津瀚洋汇和环保科技有限公司"
                                }
                            },
                        }

                        this.json_data = val

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }

                }).catch((err) => {
                    console.log(err)
                });


            },

            searchName() {
                this.data.page = 1
                this.getList()
                // 数据处理
                this.DataProcessing()
            },

            // AllTotal() {
            //     let a = list.length;
            //     for (let i = 0; i < list.length; i++) {
            //         var dic = list[i];
            //         if (dic.hasOwnProperty("children")) {
            //             a = a + dic.children.length
            //         }
            //     }
            //     return a;
            // },

            // getList(size) {

            //     if (size) {
            //         this.data.page = 1
            //         this.data.size = size
            //     }
            //     this.loading = true
            //     this.select = []
            //     this.$nextTick(() => {
            //         const start = this.data.size * (this.data.page - 1)
            //         const end = start + this.data.size
            //         this.data.total = list.length
            //         const data = []
            //         for (let i = start; i < end; i++) {
            //             if (list[i]) {
            //                 data.push(list[i])
            //             }
            //         }
            //         this.data.list = data
            //         setTimeout(() => {
            //             this.loading = false
            //         }, 1000)

            //         setTimeout(() => {
            //             this.modifyLayout();
            //         }, 1100)

            //     })

            // },

            view(row) {
                console.log(row)

                this.$refs.HazardousInfo.info(row)
            },

            edit(row) {
                console.log(row)

                this.$refs.searchRef.edit(row)

            },

            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {


                            // instance.confirmButtonLoading = true;
                            // instance.confirmButtonText = '执行中...';
                            // setTimeout(() => {
                            //     done();
                            //     setTimeout(() => {
                            //         instance.confirmButtonLoading = false;
                            //     }, 300);
                            // }, 3000);



                            this.$http.del('/api/hazardous/' + row.id).then((res) => {

                                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                                if (res.code == 200) {
                                    this.getList()
                                    // 数据处理
                                    this.DataProcessing()
                                }

                                if (res.code != 200) { //请求错误
                                    this.$message.error(res.msg);
                                }

                                // setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                                // }, 3000);

                            }).catch((err) => {
                                console.log(err)
                            });



                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                });
            },


            toSearch() {
                this.$refs.searchRef.open()
            },


            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    var a = 141 + 60 - 35 + height - 30;
                    if ($(".level_indexI .FanfuTableBox")[0]) {

                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');

                    }


                    var b = 141 + 60 + 33 + height - 30;

                    if ($(".level_indexI .el-table")[0]) {

                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');

                    }


                });

            },



            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);

                this.data.page = val

                this.getList()
                // 数据处理
                this.DataProcessing()
            },

        },



    }
</script>





<style lang="scss" scoped>
    .el-button--success {
        color: #ffffff;
        background-color: #5BD995;
        border-color: #5BD995;
    }
</style>


<style scoped>
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  } */
    /*  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini { */

    /*  .level_indexI .el-table {
    border: 1px red dotted;
    box-sizing: border-box;
  } */
</style>